@import '../../constants.scss';

.tc__header__settings-menu__language-selector {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  box-sizing: border-box;

  .MuiSelect-select:focus {
    background-color: $mui-select-focused-background;
  }
}
