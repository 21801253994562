@import '../../constants.scss';
$padding: 20px;
.tf__dashboard__section__container {
  display: flex;
  padding: 0;
  border-radius: 5px;
  padding: $padding;
  @include background-level-1;
}

.tf__detail__section__container {
  display: flex;
  padding: 0;
  border-radius: 5px;
  &[hidden] {
    opacity: 0;
    z-index: -99;
  }
}

.tc__dashboard__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > hr {
    width: 100%;
    height: 1px;
    border: 0;
    background-color: $text-color;
    margin: 10px 0px 10px 0px;
  }
}

.tc__dashboard__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .tc__dashboard__header__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      cursor: pointer;
      > svg {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}

.tf__dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  div {
    overflow: visible;
  }
  .tf__dashboard__grid {
    .MuiTable-root:nth-child(2) {
      border-spacing: 0 10px;
    }
    .tf__dashboard__filter__row {
      > th {
        background-color: transparent;
        .tf__dashboard__filter__chip {
          margin: 0 8px;
        }
        .tf__dashboard__filter__chip-select {
          margin-top: 0;
        }
      }
    }
    table {
      background-color: transparent;
    }
    tbody {
      > tr {
        height: 80px;
        padding: 10px 0;
      }
    }
    .tf__dashboard__body__row {
      border-radius: 4px;
      > td {
        border-top: 1px solid #bfc0c0;
        border-bottom: 1px solid #bfc0c0;
        font-size: 16px;
        padding-left: 0;
        &:first-child {
          border-left: 1px solid #bfc0c0;
          border-radius: 4px 0 0 4px;
          padding-left: 20px;
        }
        &:last-child {
          border-right: 1px solid #bfc0c0;
          border-radius: 0 4px 4px 0;
          padding-right: 10px;
        }
      }
      &.tf__dashboard__body__row-child {
        @include background-level-3;
        background-color: transparent;
        > td {
          background-color: #ffffff;
        }
        .tf__dashboard__body__trade {
          display: flex;
          justify-content: center;
        }
      }

      &.tf__dashboard__body__row-parent {
        @include background-level-2;
        background-color: transparent;
        cursor: pointer;
        > td {
          background-color: #fcfcfc;
        }
        &:hover,
        &:focus {
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
        }
        &.expanded {
          .tf__dashboard__parent-toggle-icon {
            transform: scaleY(-1);
          }
        }
      }
    }
    .tf__dashboard__header__row {
      > th {
        background-color: transparent;
      }
      .tf__dashboard__header__cell {
        padding: 0px 0px 3px 0px;
        border-bottom: none;
        white-space: break-spaces;

        &.tf__dashboard__header__maturity {
          padding-right: 8px;
        }
        &.tf__dashboard__header__fixedAPR {
          padding-right: 8px;
        }
        &.tf__dashboard__header__variableAPY {
          padding-right: 8px;
        }
        &.tf__dashboard__header__TVL {
          padding-right: 8px;
        }
        &.tf__dashboard__header__presentValue {
          padding-right: 8px;
        }
        &.tf__dashboard__header__availableToDeposit {
          padding-right: 8px;
        }
        .tf__dashboard__header-info-icon-container {
          margin-right: 5px;
        }
        button.MuiIconButton-root {
          > span {
            > svg {
              width: 20px;
              height: 20px;
            }
          }
        }
        > div > button {
          padding: 0px;
        }
      }
    }
    .tf__dashboard__body__apy {
      display: flex;
      justify-content: flex-end;
      .tf__dashboard__body__apy-anchor {
        display: block;
        &:hover {
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          transform: translateY(1px);
        }
      }
      .tc__infoTooltip {
        .tc__infoTooltip-popup {
          width: inherit;
        }
        .tc__infoTooltip-anchor {
          display: flex;
          > button {
            display: inline-block;
          }
          &.tc__infoTooltip-active {
            .tf__dashboard__body__apy-anchor {
              border-bottom: 1px solid rgba(0, 0, 0, 0.3);
              transform: translateY(1px);
            }
          }
        }
      }
    }
    .tf__dashboard__trade-button {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      .tc__btn {
        background-color: #2b6e84;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        width: 120px;
        height: 32px;

        &:disabled {
          color: rgba(255, 255, 255, 0.5);
          background-color: #7a7a7a;
          cursor: default;
        }
      }
    }
    .tf__dashboard__asset-ticker {
      display: flex;
      align-items: center;
      > svg {
        margin-left: 5px;
      }
    }
    .tf__dashboard__parent-toggle-icon {
      width: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    .tf__dashboard__parent-asset-data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .tf__dashboard__parent-asset-ticker {
      width: 65px;
      display: flex;
      align-items: center;
    }

    .tf__dashboard__parent-asset-chain {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      background-color: #f3f7f9;
      border: 1px solid #bfc0c0;
      box-sizing: border-box;
      border-radius: 2px;
      padding: 4px 8px 4px 8px;
    }
  }
}
