.tf__mobile-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 75px 20px 20px;
  padding: 30px 15px 20px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

  > div {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }

  > button {
    background-color: #ff6b00;
  }
}
