.tc__navBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  margin-top: 14px;
  margin-bottom: 11px;
}

.tc__navBar__left,
.tc__navBar__right {
  display: flex;
  align-items: center;
}

.tc__navBar__logo {
  font-family: 'Manrope', sans-serif;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
}
