@import '../../constants.scss';

.tc__walletSelector {
  > .MuiDialog-container {
    > .MuiPaper-root {
      background-color: white;
      padding: 15px;

      > .MuiDialogTitle-root {
        padding: 0;

        h2 {
          position: relative;
          margin-bottom: 15px;

          > button {
            position: absolute;
            right: -15px;
            top: -12px;
          }
        }
      }

      > .MuiDialogContent-root {
        padding: 0;

        .tc__walletSelector__item {
          margin: 0 auto 15px;
          border: 1px solid #f1f1f1;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          width: 338px;
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .MuiTypography-root {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: $text-color;

            > a {
              color: $link-color;
              text-decoration: none;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
