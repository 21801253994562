@import '../../constants.scss';
@import '../shared/style.scss';

.tf__dialog__section {
  border: 2px solid transparent !important;
  width: 100%;
}

.tf__dialog__section-elevation-1 {
  @include background-level-1;
}

.tf__dialog__section-elevation-2 {
  @include background-level-2;
}

.tf__dialog__section-elevation-3 {
  @include background-level-3;
}

.tc__dialog__section-selected {
  border: 2px solid #2b5f97 !important;
  box-shadow: 0px 2px 2px rgba(43, 95, 151, 0.5);

  background-color: white;
}

.tf__dialog__section-title {
  display: flex;
  align-items: center;
}

.tc__dialog__section-content {
  width: 100%;

  .tf__tab__warning {
    .tf__tab__warning__title {
      display: flex;
      margin-bottom: 22px;

      svg {
        margin-right: 10px;
      }
    }
  }

  .tc__dialog__section-content-selectable {
    cursor: pointer;
  }
}
