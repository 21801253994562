.tc__deposit {
  margin-right: 15px;
}

.tc__deposit__yield-body__row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 6px;
  box-sizing: border-box;
}

.tc__deposit__yield-body__row__underline {
  @extend .tc__deposit__yield-body__row;
  border-bottom: 1px solid #e4e4e4;
}

.tc__deposit__card-row-title {
  width: 200px;
  flex-shrink: 0;
}

.tc__deposit__card-row-change {
  width: 100%;
  text-align: right;
}

.tc__deposit__card-row-value {
  width: 130px;
  text-align: right;
  flex-shrink: 0;
}

.tc__deposit__yield-name-card {
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  padding: 5px 13px;
}

.tc__deposit_insufficient-liquidity-message {
  display: flex;
  width: 300px;

  > div {
    text-align: justify;
    text-justify: distribute;
  }
}
