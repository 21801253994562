.tc__feesTooltip {
  padding: 4px 0px;
  width: 400px;
  cursor: auto;
}

.tc__feesTooltip-row {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
