@import '../../constants.scss';

.tc__token-selector-container {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 90px;

  .MuiSelect-select:focus {
    background-color: $mui-select-focused-background;
  }
}

.tc__token-selector-ticker-container {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.tf__token-selector__menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
