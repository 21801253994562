@import '../../constants.scss';

.tc__sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 12px 0;
  border-radius: 5px 0px 0px 5px;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  background: $card-background;
  box-sizing: border-box;
  width: 170px;

  > * {
    padding-left: 10px;
  }

  .tc__sidebar-section-breadcrumbs {
    display: flex;
    align-self: flex-start;
    text-decoration: none;
    cursor: pointer;

    &::before {
      content: ' ';
      position: relative;
      display: block;
      width: 12px;
      height: 16px;
      background-image: $left-pointing-angle;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .tc__sidebar-pool {
    display: flex;
    width: calc(100% - 22px);
    padding-right: 12px;
    align-self: flex-start;
    justify-content: space-between;

    .tc__sidebar-pool-title {
      div[data-variant='h5'] {
        font-weight: 700 !important;
        line-height: 24px !important;
      }

      div[data-variant='sub-title'] {
        font-weight: 700 !important;
        line-height: 16px !important;
        color: $dark-title-color !important;
      }
    }

    .tc__sidebar-pool-icon {
      position: relative;
      width: 52px;
      height: 40px;

      svg {
        position: absolute;

        &:nth-child(2) {
          top: 16px;
          left: 28px;
          border-radius: 50%;
          border: 2px solid white;
        }
      }
    }
  }

  .tc__sidebar-section-title {
    height: 56px;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div[data-variant='h5'] {
      font-weight: 700 !important;
      line-height: 24px !important;
      color: $dark-title-color !important;
    }

    div[data-variant='sub-title'] {
      font-weight: 500 !important;
      font-size: 10px !important;
      line-height: 16px !important;
    }
  }

  .tc__sidebar-view-item {
    display: flex;
    align-items: center;
    align-self: flex-start;
    width: 100%;
    height: 48px;
    padding-left: 20px;

    div[data-variant='h5'] {
      font-weight: 700 !important;
      line-height: 24px !important;
      transition: font-size 150ms linear;
    }

    &:hover:not(.selected):not(.disabled) {
      div[data-variant='h5'] {
        font-size: 17px !important;
      }
    }

    &.selected {
      background: linear-gradient(97.53deg, #4ea3c8 0%, #285a95 100%);
      width: calc(100% + 24px);
      border-radius: 0 24px 24px 0;
      z-index: 1;
    }

    &.disabled {
      cursor: default;

      div[data-variant='h5'] {
        color: $faint-text-color;
        opacity: 0.6;
      }
    }
  }

  .tc__sidebar-section-footer {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-self: start;
    flex: 1;

    .tc__sidebar-section-contract-addr {
      display: flex;

      .tc__infoTooltip {
        margin: 0 4px;
      }
    }
  }

  a {
    &:hover {
      text-decoration: underline $title-color;
    }
  }
}
