@import '../shared/style.scss';

.tc__currentPosition {
  @include cardContainer;
  background: rgba(255, 255, 255, 0.5); // TODO add to constants
}

.tc__currentPosition-header-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 5px;
}

.tc__currentPosition-data-row {
  display: flex;
  width: 100%;
}

.tc__currentPosition-data-row-label {
  display: flex;
  align-items: center;
  width: 43%;

  > div:first-child {
    min-width: 12px;
  }
}

.tc__currentPosition-data-row-percentage {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 35%;
}

.tc__currentPosition-data-row-value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 35%;
}

.tc__currentPosition-legend-icon {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 5px;
}

.tc__currentPosition-unstaked-principals-icon {
  @extend .tc__currentPosition-legend-icon;
  background: #f5ac37;
}

.tc__currentPosition-unstaked-yields-icon {
  @extend .tc__currentPosition-legend-icon;
  background: #288195;
}

.tc__currentPosition-staked-principals-icon {
  @extend .tc__currentPosition-legend-icon;
  background-color: #f5ac37;
  background-image: url('../../images/pattern-principals.svg');
}

.tc__currentPosition-staked-yields-icon {
  @extend .tc__currentPosition-legend-icon;
  background-color: #288195;
  background-image: url('../../images/pattern-yields.svg');
}
