// Colors - Light Theme
$background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
  linear-gradient(117.67deg, #d6d6d6 6.84%, #597b91 84.65%);

$background-color: #f1f1f1;

@mixin background-level-1 {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px;
}

@mixin background-level-2 {
  background: #fcfcfc;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px;
}

@mixin background-level-3 {
  background-color: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border: 1px solid #fcfcfc;
  border-radius: 5px;
}

@mixin tooltip {
  background: linear-gradient(0deg, #ffffff -0.55%, #f3f7f9 100%);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 5px 25px 8px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

$text-color: #222222;
$inverted-text-color: white;
$faint-text-color: #c4c4c4;
$ui-element-color: #e2e2e2;
$accent-color-1: #f5ac37;
$accent-color-2: #f24c00;
$accent-color-3: #065f73;
$success: #4bb543;
$alert: #ff0f0f;
$link-color: #047295;
$title-color: #7a7a7a;
$dark-title-color: #474350;
$disabled-color: #bfc0c0;
$contract-addr-color: #2b6e84;
$card-background: rgba(255, 255, 255, 0.7);

// Breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1500px;
$breakpoint-xl: 1920px;

// Spacing
$grid-template-rows: 74px;
$row-gap: 7px;

$grid-template-columns: 196px;
$column-gap: 0;

$sidebar-margin-top: $grid-template-rows + $row-gap;

// UI Elements
$mui-select-focused-background: white;

@mixin default-font-face {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: $text-color;
}

// UI decoration image icon
$left-pointing-angle: url("data:image/svg+xml,%3Csvg width='4' height='8' viewBox='0 0 4 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.88182 7.23784C3.91927 7.27914 3.94899 7.32826 3.96927 7.38239C3.98956 7.43652 4 7.49458 4 7.55322C4 7.61186 3.98956 7.66992 3.96927 7.72405C3.94899 7.77818 3.91927 7.82731 3.88182 7.86861C3.84468 7.91024 3.80049 7.94329 3.7518 7.96584C3.70311 7.98839 3.65089 8 3.59815 8C3.5454 8 3.49318 7.98839 3.44449 7.96584C3.39581 7.94329 3.35162 7.91024 3.31448 7.86861L0.118182 4.315C0.0807343 4.27371 0.0510112 4.22458 0.0307272 4.17045C0.0104433 4.11632 0 4.05826 0 3.99962C0 3.94098 0.0104433 3.88292 0.0307272 3.82879C0.0510112 3.77466 0.0807343 3.72553 0.118182 3.68424L3.31448 0.130636C3.38971 0.0469911 3.49175 0 3.59815 0C3.65083 0 3.703 0.0115366 3.75167 0.0339511C3.80034 0.0563656 3.84457 0.0892191 3.88182 0.130636C3.91907 0.172052 3.94862 0.221221 3.96878 0.275334C3.98894 0.329448 3.99932 0.387446 3.99932 0.446018C3.99932 0.50459 3.98894 0.562588 3.96878 0.616702C3.94862 0.670815 3.91907 0.719984 3.88182 0.7614L0.9652 3.99962L3.88182 7.23784Z' fill='%237A7A7A'/%3E%3C/svg%3E%0A");
