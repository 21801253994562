@import '../../../constants.scss';
.tc__aprTooltip {
  padding: 4px 0px;
  min-width: 400px;
  cursor: auto;
  > hr {
    width: 100%;
    height: 1px;
    border: 0;
    background-color: $ui-element-color;
    margin: 15px 0 0;
  }
}

.tc__aprTooltip-row {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}

.tc__aprPoolRation {
  position: relative;
  height: 40px;
  width: 75px;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    $ui-element-color 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
  > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
  }
  > div:last-child {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
