.tc__chainSelectorPopup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.tc__chainSelectorPopup-button {
  background: white;
  border: 1px solid #bfc0c0;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;

  &.selected {
    border: 2px solid #2b6e84;
    padding-left: 15px;
    padding-top: -1px;
    padding-right: 15px;
    padding-bottom: -1px;
  }

  &:hover {
    border: 2px solid #2b6e84;
    padding-left: 15px;
    padding-top: -1px;
    padding-right: 15px;
    padding-bottom: -1px;
  }
}

.tc__chainSelectorPopup-button__label {
  display: flex;
  align-items: center;
}

.tc__chainSelectorPopup-network {
  padding-left: 18px;
  padding-right: 18px;
  background-color: #f3f7f9;
  border-radius: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
