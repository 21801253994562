.tc__infoTooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  .tc__infoTooltip-anchor {
    > button {
      cursor: pointer;
      background: inherit;
      border: none;
      padding: 0;
      display: flex;
    }
  }
}

.tc__infoTooltip__popper {
  z-index: 999;
  .tc__infoTooltip-arrow {
    overflow: hidden;
    position: absolute;
    width: 20px;
    height: 13px;
    box-sizing: border-box;
    &::before {
      content: '';
      margin: auto;
      display: block;
      width: 100%;
      height: 100%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border: 1px solid rgba(0, 0, 0, 0.2);
      background-color: #fcfcfc;
      transform: rotate(45deg);
    }
  }
  &[x-placement*='bottom'] {
    margin-top: 8px;
    .tc__infoTooltip-arrow {
      top: 0;
      left: 0;
      margin-top: -12px;
      margin-left: 4px;
      margin-right: 4px;
      &::before {
        transform-origin: 0 100%;
      }
    }
  }
  &[x-placement*='top'] {
    margin-bottom: 8px;
    .tc__infoTooltip-arrow {
      bottom: 0;
      left: 0;
      margin-bottom: -12px;
      margin-left: 4px;
      margin-right: 4px;
      &::before {
        transform-origin: 100% 0;
      }
    }
  }
  &[x-placement*='right'] {
    margin-left: 8px;
    .tc__infoTooltip-arrow {
      left: 0;
      margin-left: -12px;
      margin-left: 4px;
      margin-right: 4px;
      &::before {
        transform-origin: 100% 100%;
      }
    }
  }
  &[x-placement*='left'] {
    margin-right: 8px;
    .tc__infoTooltip-arrow {
      right: 0;
      margin-right: -12px;
      margin-left: 4px;
      margin-right: 4px;
      &::before {
        transform-origin: 0 0;
      }
    }
  }
}

.tc__infoTooltip-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.tc__infoTooltip-popup {
  background: #fcfcfc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1;
  cursor: default;
  .tc__infoTooltip-popup-text {
    width: 350px;
  }
}
