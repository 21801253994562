@import '../../constants.scss';
@import '../shared/style.scss';

.tc__profitLoss {
  @include cardContainer;
  background: rgba(255, 255, 255, 0.5); // TODO add to constants
  flex-grow: 0;
}

.tc__profitLossChartTooltip {
  @include tooltip;
}
