@import '../../constants.scss';

.tc__header__popper {
  z-index: 999;
}

.tc__header__settings-menu__container {
  @include background-level-2;
  padding: 15px 0px 15px 0px;
  max-width: 280px;
}

.tc__header__settings-menu__section-header {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.tc__header__settings-menu__section-row {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  box-sizing: border-box;

  .tc__percentage-input-container {
    width: 100%;
    padding: 0;

    input {
      padding: 0 15px;
    }
  }
}

.tc__header__settings-menu__section-header-action {
  padding-left: 10px;
  margin-left: auto;
}
