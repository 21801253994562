.tc__modal-backdrop {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: default;
}

.tc__modal {
  min-width: 340px;
  min-height: 75px;
  background-color: white;
  border: 1px solid #f3f7f9;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.tc__modal-header {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tc__modal-header-close {
  cursor: pointer;
}
