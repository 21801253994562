.tf__dashboard__body__protocol_label {
  text-transform: capitalize;
}

.tf__dashboard__body__protocol_container {
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }

  span {
    padding-left: 10px;
  }
}

.tf__dashboard__body__protocol-icons_container {
  display: flex;

  svg {
    margin-right: 10px;
  }
}
