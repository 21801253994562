@import '../../constants.scss';

.tc__notification-container {
  position: absolute;
  top: 65px;
  right: 0;
  z-index: 9999;
}

.tc__notification {
  @include background-level-3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 274px;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  .tc__notification__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 14px;
  }

  .tc__notification__title {
    display: flex;
    align-items: center;
    flex: 1;

    > svg {
      margin-right: 8px;
    }
  }

  .tc__notification__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 24px;
  }

  .tc__notification__link {
    margin-top: 14px;
    > a {
      color: $link-color;
    }
  }
}
