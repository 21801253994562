@import '../../../constants.scss';

.tf__dashboard__grid__maturity-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tf__dashboard__grid__maturity-date {
  display: flex;
  align-items: center;
}

.tf__dashboard__grid__maturity-matured-label {
  padding: 4px 8px;
  background-color: #cef2da;
  border: 1px solid #4bb543;
  box-sizing: border-box;
  border-radius: 2px;
  float: left;
}
