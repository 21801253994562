@import '../../constants.scss';

.tc__pending {
  @include background-level-3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 274px;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  .tc__pending__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 14px;
  }

  .tc__pending__title {
    display: flex;
    align-items: center;
    flex: 1;

    > div:first-child {
      margin-right: 8px;
    }
  }

  .tc__pending__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    white-space: pre-line;
    padding-left: 28px;
  }

  .tc__pending__link {
    margin-top: 14px;
    > a {
      color: $link-color;
    }
  }
}

.tc__wallet__popper__section-transactions {
  .tc__pending {
    box-sizing: border-box;
    padding: 5px;
    width: 100%;
    max-width: 341px;

    .MuiButtonBase-root.MuiAccordionSummary-root {
      min-height: 28px;

      .MuiAccordionSummary-content {
        margin: 4px 0;
      }

      .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .tc__pending__header {
        margin-bottom: 0;
      }
    }
  }
}
