.tc__wallet__popper__section-transactions {
  .tc__notification {
    box-sizing: border-box;
    padding: 5px;
    width: 100%;
    max-width: 341px;

    .MuiButtonBase-root.MuiAccordionSummary-root {
      min-height: 28px;

      .MuiAccordionSummary-content {
        margin: 4px 0;
      }

      .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .tc__notification__header {
        margin-bottom: 0;
      }
    }
  }
}
