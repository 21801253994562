@import '../../constants.scss';

.tc__header__popper {
  padding-top: 5px;
  z-index: 999;
}

.tc__header__community-menu__container {
  @include background-level-2;
  padding: 0px;
  width: 256px;

  .tc__header__community-menu__icon-container {
    min-width: 28px;
  }

  .MuiListItem-gutters {
    padding-left: 20px;
    padding-right: 20px;
  }

  .MuiListItem-root {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
