@import '../../constants.scss';

.tc__percentage-input-container {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
  padding-left: 15px;
  height: 40px;
  width: 165px;
  border: 1px solid #bfc0c0;

  input {
    padding: 0px;
  }

  .MuiButton-root {
    height: 40px;
    border-radius: 0px 5px 5px 0px;
    text-transform: none;
    min-width: auto;

    &.MuiButton-textPrimary {
      background: #2b6e84;
    }
  }

  .MuiDivider-root {
    background-color: #bfc0c0;
  }
}

.tc__percentage-input-disabled {
  input[type='text'] {
    color: $faint-text-color;
  }
}

.tc__percentage-input-tooltip {
  position: absolute;
  transform: translateY(100%);
  bottom: -8px;
  left: 0px;
  background: #fcfcfc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 15px;
}
