.tc__dashboard__grid__avail-to-deposit__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.tc__dashboard__grid__avail-to-deposit-token-ticker-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
